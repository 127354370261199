/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

function Information() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Helping Seniors</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            Live Life to the Fullest
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            93% of retirement community residents reported that they felt there was a safety net in
            place for them if something went wrong, compared to only 40% of non-residents.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Sarah Martinez"
              date="1 day ago"
              review="Finding Mom's perfect assisted living home felt impossible. Senior Placement Services listened to our needs, presented great options, and now Mom thrives in a safe, fun environment. Huge thanks!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="David Lynch"
              date="1 week ago"
              review="We worried about Dad's senior living options. Senior Placement Services eased our concerns, listened patiently, and found the perfect fit for Dad. Highly recommend!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Emily Kim"
              date="3 weeks ago"
              review="Grandma's senior living community search was overwhelming. Senior Placement Services took the time to understand her, offered perfect choices, and made it easy. Thank you!"
              rating={5}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
